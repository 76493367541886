// import React from "react";
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import { FormControl } from '@mui/base';
// import TextField from "@mui/material/TextField";
// import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

// const Waitlist = ({ joinWaitlist }) => {
//   const handleSubmit = () => {
//     joinWaitlist();
//   }
//   return (
//     <section id="waitlist">
//       <Box sx={{
//         display: 'flex',
//         alignItems: 'center',
//         backgroundColor: '#F5F6FA',
//         paddingTop: { xs: '20px', sm: '20px', md: '40px'},
//         paddingBottom: { xs: '20px', sm: '20px', md: '40px'},
//       }}>
//         <Container>
//           <Typography
//             variant="h3"
//             sx={{
//               marginBottom: { xs: '20px', md: '40px' },
//               fontSize: { xs: '28px', lg: '36px' },
//               fontStyle: 'normal',
//               fontWeight: 'bold',
//               textAlign: 'center',
//             }}
//           >
//             Be one of the first using our platform
//           </Typography>
//           <FormControl>
//             <Box
//               sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//               }}
//             >
//               <Grid
//                 container
//                 spacing={1}
//                 sx={{
//                   maxWidth: '600px',
//                 }}
//               >
//                 <Grid xs={12} md={6}>
//                   <TextField fullWidth id="outlined-basic" label="First name" variant="outlined" sx={{ marginBottom: '10px' }} />
//                 </Grid>
//                 <Grid xs={12} md={6}>
//                   <TextField fullWidth id="outlined-basic" label="Last name" variant="outlined" sx={{ marginBottom: '10px' }} />
//                 </Grid>
//                 <Grid xs={12}>
//                   <TextField fullWidth id="outlined-basic" label="Email" variant="outlined" sx={{ marginBottom: '10px' }} />
//                 </Grid>
//                 <Grid xs={12}>
//                   <TextField fullWidth id="outlined-basic" label="Mobile" variant="outlined" sx={{ marginBottom: '10px' }} />
//                 </Grid>
//                 <Grid xs={12}>
//                   <TextField fullWidth id="outlined-basic" label="City" variant="outlined" sx={{ marginBottom: '10px' }} />
//                 </Grid>
//               </Grid>
//               <Button variant="contained" sx={{ backgroundColor: '#5E74FE', color: 'white', marginTop: '10px' }} onClick={handleSubmit}>
//                 Join the waitlist
//               </Button>
//             </Box>
//           </FormControl>
//         </Container>
//       </Box>
//     </section>
//   );
// }

// export default Waitlist;

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  mobile: Yup.string()
    .matches(/^\+?[0-9]{7,15}$/, 'Invalid phone number')
    .required('Mobile is required'),
  city: Yup.string().required('City is required'),
});

const MyForm = ({ joinWaitlist }) => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      city: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log(values);
      joinWaitlist({
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        mobile: values.mobile,
        city: values.city,
      })
    },
  });

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#F5F6FA',
      paddingTop: { xs: '20px', sm: '20px', md: '40px'},
      paddingBottom: { xs: '20px', sm: '20px', md: '40px'},
    }}>
      <Container>
        <Typography
          variant="h3"
          sx={{
            marginBottom: { xs: '20px', md: '40px' },
            fontSize: { xs: '28px', lg: '36px' },
            fontStyle: 'normal',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Be one of the first using our platform
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                id="firstName"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                id="lastName"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                id="email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Mobile"
                variant="outlined"
                fullWidth
                id="mobile"
                name="mobile"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                id="city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12}>
               <Button type="submit" variant="contained" color="primary" sx={{ fontSize: '18px' }}>
                 Join the waitlist
               </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default MyForm;