import React, { useState } from 'react'
import styled from 'styled-components'
import { saveAs } from 'file-saver'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CheckboxContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
`

const ImageListItemTouchable = styled(ImageListItem)`
  overflow: hidden;
`


const Photos = ({ isPaid, photoUrls, thumbnailUrls }) => {
  const theme = useTheme();
  const [selected, setSelected] = useState(isPaid ? photoUrls.reduce((acc, _, i) => {
    acc[i] = false
    return acc
  }, {}) : null)
  const [showPhoto, setShowPhoto] = useState(null)
  const showShare = !!navigator?.share
  const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDownload = () => {
    if (Object.values(selected).every(value => !value)) {
      window.alert('Please select at least one photo to download')
      return
    }

    const bucketUrls = photoUrls.filter((_, i) => selected[i]);
    bucketUrls.forEach(bucketUrl => fetch(bucketUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        saveAs(url, 'photogig.jpg')
      })
    )
  }

  const generateFiles = async (urls) => {
    const files = []
    for (let i = 0; i < urls.length; i++) {
      const url = urls[i]
      const response = await fetch(url)
      const blob = await response.blob()
      const file = new File([blob], `image-${i}.jpg`, { type: "image/jpeg" })
      files.push(file)
    }
    return files
  }

  const handleShare = async () => {
    if (Object.values(selected).every(value => !value)) {
      window.alert('Please select at least one photo to download')
      return
    }
    console.log({ selected, photoUrls })
    const selectedUrls = photoUrls.filter((_, i) => selected[i]);

    try {
      const files = await generateFiles(selectedUrls);
      await navigator.share({
        files: files,
      });
    } catch (err) {
      console.error("Error sharing file:", err);
    }
  }

  const handleOpen = (p) => {
    setShowPhoto(p)
  }

  const handleClose = () => {
    setShowPhoto(null)
  }

  const selectPhoto = (i) => {
    return (event) => {
      event.stopPropagation()
      setSelected({ ...selected, [i]: !selected[i] })
    }
  }

  const selectAll = (selectedValue) => () => {
    console.log({selectedValue})
    const allSelected = photoUrls.reduce((acc, _, i) => {
      acc[i] = selectedValue
      return acc
    }, {})

    setSelected(allSelected)
  }

  const allSelected = isPaid ? Object.values(selected).every(value => value) : false;
  const modalWidth = Math.min(window.screen.width - 20, 800);
  const listWidth = matchDownMd ? window.screen.width - 20 : 500;
  const numberOfColumns = matchDownMd ? 2 : 3;
  const gap = 10;
  const rowHeight = (listWidth - ((numberOfColumns - 1) * gap)) / numberOfColumns;

  return (
    <>
      <Modal
        open={!!showPhoto}
        onClose={handleClose}
        aria-labelledby="photo-full-size"
        aria-describedby="photo-full-size"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: modalWidth,
          boxShadow: 24,
        }}>
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
            <CloseIcon />
          </IconButton>
          <img
            srcSet={showPhoto}
            src={showPhoto}
            loading="lazy"
            alt=""
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      </Modal>
      <ImageList sx={{ width: listWidth, height: 450 }} cols={matchDownMd ? 2 : 3 } rowHeight={rowHeight} gap={gap} >
        {isPaid
          ? photoUrls.map((photoUrl, i) => (
            <ImageListItemTouchable onClick={() => handleOpen(photoUrl)} key={photoUrl}>
              <img
                srcSet={photoUrl}
                src={photoUrl}
                loading="lazy"
                alt=""
              />
              <CheckboxContainer>
                <Checkbox
                  checked={selected[i]}
                  onClick={selectPhoto(i)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </CheckboxContainer>
            </ImageListItemTouchable>
          ))
          : thumbnailUrls.map((photoUrl) => (
            <ImageListItem key={photoUrl} style={{ overflow: 'hidden' }} sx={{ overflow: 'hidden' }} >
              <img
                srcSet={photoUrl}
                src={photoUrl}
                loading="lazy"
                alt=""
                // style={{
                //   width: '100%',
                //   height: '100%',
                //   resizeMode: 'cover',
                // }}
              />
            </ImageListItem>
          ))
        }
      </ImageList>
      {isPaid ? (
        <>
          {photoUrls.length > 1 ? (
            <Button
              variant="text"
              onClick={selectAll(!allSelected)}
            >
              {allSelected ? 'Select none' : 'Select all'}
            </Button>
          ) : null }
          <Button
            variant="contained"
            sx={{ backgroundColor: '#5E74FE', color: 'white', marginTop: '10px' }}
            onClick={showShare ? handleShare : handleDownload}
          >
            Download
          </Button>
        </>
      ) : null }
    </>
  );
}

export default Photos
