import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';

import Button from '../WhiteButton';

const Hero = () => {
  return (
    <Box sx={{
      minHeight: "80vh",
      marginTop: '-100px',
      paddingTop: '100px',
      backgroundImage: 'linear-gradient(rgba(94, 116, 254, 0.67), rgba(94, 116, 254, 0.87)), url(../photographer.jpg)',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: 'flex',
      alignItems: 'center',
    }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: 'center',
            alignItems: 'space-between',
            flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }
          }}
        >
          <Box
            sx={{
              flex: "1",
              display: 'flex',
              flexDirection: 'column',
              marginBottom: { xs: '30px', md: '0' },
              alignItems: 'flex-start',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                marginTop: { xs: '100px', sm: '100px', md: '200px'},
                color: 'white',
                fontSize: { xs: '48px', lg: '56px' },
                fontStyle: 'normal',
                fontWeight: 'bold',
              }}
            >
              Let's democratise photography
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "18px", color: "#5A6473", my: 4, color: 'white', marginRight: { sm: '50px'} }}
            >
              We're creating a platform where you can offer your service the way you want to: your packages, your prices, your choice. Do you want to be part of this revolution?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: { xs: '30px', md: '0' },
              }}
            >
              <Button
                variant="contained"
                sx={{ fontSize: '18px' }}
              >
                  Join the waitlist
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Hero;