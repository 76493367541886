import Container from '@mui/material/Container'

import Logo from '../components/Logo';

const Terms = () => {
  return (
    <Container>
      <Logo mode="color" noMargin />
      <p>MOBILE APP END USER LICENSE AGREEMENT</p>
      <p>Last modified: NOVEMBER 13, 2021</p>
      <p>This Mobile App End User License Agreement (the "Agreement") is a binding agreement between YOU ("End User" or "you") and Photogig ("Company"). This Agreement governs your use of the mobile application Photogig, including all related documentation (the "App"). The App is licensed, not sold, to you.</p>
      <p>BY DOWNLOADING, INSTALLING, AND/OR USING THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 13 YEARS OF AGE OR OLDER; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT DOWNLOAD, INSTALL, AND/OR USE THE APPLICATION.</p>
      <ol>
        <li>License Grant. Subject to the terms of this Agreement, Company grants you a limited, non-exclusive, and nontransferable license to:
          <ol style={{ listStyleType: 'lower-alpha'}}>
            <li>download, install, and use the App for your personal, non-commercial use on a single mobile device owned or otherwise controlled by you ("Mobile Device") strictly in accordance with the App's documentation; and</li>
            <li>access, download, and use on such Mobile Device the App, strictly in accordance with this Agreement and the Terms and Conditions and Privacy Policy applicable to the App as set forth in Section 5</li>
          </ol>
        </li>
        <li>License Restrictions. Licensee shall not:
          <ol style={{ listStyleType: 'lower-alpha'}}>
            <li>copy the App, except as expressly permitted by this license;</li>
            <li>modify, translate, adapt, or otherwise create derivative works or improvements, whether patentable or not, of the App;</li>
            <li>reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the App or any part thereof;</li>
            <li>remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the App, including any copy thereof;</li>
            <li>rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the App, or any features or functionality of the App, to any third party for any reason, including by making the App available on a network where it is capable of being accessed by more than one device at any time;</li>
            <li>remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights management, or security features in or protecting the App; or</li>
          </ol>
        </li>
        <li>Reservation of Rights. You acknowledge and agree that the App is provided under license, and not sold, to you. You do not acquire any ownership interest in the App under this Agreement, or any other rights thereto other than to use the App in accordance with the license granted, and subject to all terms, conditions, and restrictions, under this Agreement. Company reserves and shall retain its entire right, title, and interest in and to the App, including all copyrights, trademarks, and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.</li>
        <li>Collection and Use of Your Information. You acknowledge that when you download, install, or use the App, Company may use automatic means (including, for example, cookies and web beacons) to collect information about your Mobile Device and about your use of the App. You also may be required to provide certain information about yourself as a condition to downloading, installing, or using the App or certain of its features or functionality [,and the App may provide you with opportunities to share information about yourself with others]. All information we collect through or in connection with this App is subject to our Privacy Policy located within the App [Settings > Privacy Policy]. By downloading, installing, using, and providing information to or through this App, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</li>
        <li>Terms and Conditions; Privacy Policy. Your access and use of the App are governed by the App’s Terms and Conditions and Privacy Policy located at within the App, which are incorporated herein by this reference. Your access to and use of the App may require you to acknowledge your acceptance of such Terms and Conditions and Privacy Policy and/or to register with the App, and your failure to do so may restrict you from accessing or using certain of the App's features and functionality. Any violation of such Terms and Conditions will also be deemed a violation of this Agreement.</li>
        <li>Geographic Restrictions. The Content and Services are provided for access and use worldwide. You acknowledge that you may not be able to access all or some of the Content and Services in your country and that access thereto may not be legal by certain persons or in certain countries. If you access the Content and Services in your country, you are responsible for compliance with local laws.</li>
        <li>Updates. Company may from time to time in its sole discretion develop and provide App updates, which may include upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including related documentation, "Updates"). Updates may also modify or delete in their entirety certain features and functionality. You agree that Company has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. Based on your Mobile Device settings, when your Mobile Device is connected to the internet either:
          <ol style={{ listStyleType: 'lower-alpha'}}>
            <li>the App will automatically download and install all available Updates; or</li>
            <li>you may receive notice of or be prompted to download and install available Updates.</li>
          </ol>
          You shall promptly download and install all Updates and acknowledge and agree that the App or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the App and be subject to all terms and conditions of this Agreement.
        </li>
        <li>Third-Party Materials. The App may display, include, or make available third-party content (including data, information, applications, and other products, services, and/or materials) or provide links to third-party websites or services, including through third-party advertising ("Third-Party Materials"). You acknowledge and agree that Company is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Company does not assume and will not have any liability or responsibility to you or any other person or entity for any Third-Party Materials. Third Party Materials and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.</li>
        <li>Term and Termination.
          <ol style={{ listStyleType: 'lower-alpha'}}>
            <li>The term of Agreement commences when you download/install the App and will continue in effect until terminated by you or Company as set forth in this Section 9.</li>
            <li>You may terminate this Agreement by deleting the App and all copies thereof from your Mobile Device.</li>
            <li>Company may terminate this Agreement at any time without notice [if it ceases to support the App, which Company may do in its sole discretion, if the Company discovers you are under the age of 18, you fail to abide by the Terms and Conditions set forth in the App [More> Settings > Terms and Conditions], or if you violate any of the terms and conditions of this Agreement.</li>
            <li>Upon termination:
              <ol style={{ listStyleType: 'lower-roman'}}>
                <li>all rights granted to you under this Agreement will also terminate; and</li>
                <li>you must cease all use of the App and delete all copies of the App from your Mobile Device and account.</li>
              </ol>
            </li>
            <li>Termination will not limit any of Company's rights or remedies at law or in equity.</li>
          </ol>
        </li>
        <li>Disclaimer of Warranties. THE APPLICATION IS PROVIDED TO LICENSEE "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</li>
        <li>Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES FOR:
          <ol style={{ listStyleType: 'lower-alpha'}}>
            <li>PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, MOBILE DEVICE FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.</li>
            <li>DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION. THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.</li>
          </ol>
        </li>
        <li>Indemnification. You agree to indemnify, defend, and hold harmless Company and its officers, directors, employees, agents, affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including [reasonable] attorneys' fees, arising from or relating to your use or misuse of the App or your breach of this Agreement, including but not limited to the content you submit or make available through this App.</li>
        <li>Export Regulation. The App may be subject to export control laws, including the Export Control Reform Act and its associated regulations. You shall not, directly or indirectly, export, re-export, or release the App to, or make the App accessible from, any jurisdiction or country to which export, re-export, or release is prohibited by law, rule, or regulation. You shall comply with all applicable federal laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval), prior to exporting, re-exporting, releasing, or otherwise making the App available anywhere in the world.</li>
        <li>US Government Rights. The App is commercial computer software, as such term is defined in 48 C.F.R. §2.101. Accordingly, if you are an agency of the US Government or any contractor therefor, you receive only those rights with respect to the App as are granted to all other end users under license, in accordance with (a) 48 C.F.R. §227.7201 through 48 C.F.R. §227.7204, with respect to the Department of Defense and their contractors, or (b) 48 C.F.R. §12.212, with respect to all other US Government licensees and their contractors.</li>
        <li>Severability. If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect.</li>
        <li>Governing Law. This Agreement is governed by and construed in accordance with the internal laws of of Norway without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising out of or related to this Agreement or the App shall be instituted exclusively in the federal courts of Norway. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</li>
        <li>Arbitration. Any disputes arising from these Terms and Conditions or use of the App, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of Norway, applying Norwegian law.</li>
        <li>Limitation of Time to File Claims. ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE APPLICATION MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</li>
        <li>Entire Agreement. This Agreement, together with both our Terms and Conditions and Privacy Policy, constitute the entire agreement between you and Company with respect to the App and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the App.</li>
        <li>Waiver. No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this Agreement andany applicable purchase or other terms, the terms of this Agreement shall govern.</li>
      </ol>
      <h3 style={{ textDecoration: 'underline' }}>Safety Tips</h3>
      <p>Please be aware that your last login information is available through the app as a way to make users find other users nearby to connect and have a photo gig. Please be cautious when interacting with someone you don’t know. Use your best judgment and put your safety first, whether you are exchanging initial messages or meeting in person for a photo gig. While you can’t control the actions of others, there are things you can do to help you stay safe during your experience.</p>
      <h3 style={{ textDecoration: 'underline' }}>Online Safety</h3>
      <h3 style={{ color: '#5E74FE' }}>Never Send Money or Share Financial Information</h3>
      <p>Never send money, especially over wire transfer, even if the person claims to be in an emergency. Wiring money is like sending cash — it’s nearly impossible to reverse the transaction or trace where the money went. Never share information that could be used to access your financial accounts. If another user asks you for money, report it to us immediately.</p>
      <h3 style={{ color: '#5E74FE' }}>Protect Your Personal Information</h3>
      <p>Never share personal information, such as your social security number, home or work address, or details about your daily routine (e.g., that you go to a certain gym every Monday) with people you don’t know. If you are a parent, limit the information that you share about your children on your profile and in early communications. Avoid sharing details such as your children’s names, where they go to school, or their ages or genders.</p>
      <h3 style={{ color: '#5E74FE' }}>Report All Suspicious and Offensive Behavior</h3>
      <p>You know when someone’s crossed the line and when they do, we want to know about it. Block and report anyone that violates our terms. Here are some examples of violations:</p>
      <ul>
        <li>Requests for money or donations</li>
        <li>Underage users</li>
        <li>Harassment, threats, and offensive messages</li>
        <li>Inappropriate or harmful behavior during or after meeting in person</li>
        <li>Fraudulent profiles</li>
        <li>Spam or solicitation including links to commercial websites or attempts to sell products or services</li>
      </ul>
      <p>You can report any concerns about suspicious behavior from any profile page or messaging window to photogigger@gmail.com.</p>
      <h3 style={{ color: '#5E74FE' }}>Protect Your Account</h3>
      <p>Be sure to pick a strong password, and always be careful when logging into your account from a public or shared computer. Photogig will never send you an email asking for your username and password information — if you receive an email asking for account information, report it immediately.</p>
      <h3 style={{ textDecoration: 'underline' }}>Meeting in Person</h3>
      <h3 style={{ color: '#5E74FE' }}>Don’t Be In A Rush</h3>
      <p>Take your time and get to know the other person before agreeing to meet. Don’t be afraid to ask questions to screen for any red flags or personal dealbreakers. A phone or video call can be a useful screening tool before meeting.</p>
      <h3 style={{ color: '#5E74FE' }}>Meet in Public and Stay in Public</h3>
      <p>Meet for the first few times in a populated, public place — never at your home, someone else’s home, or any other private location.</p>
      <h3 style={{ color: '#5E74FE' }}>Tell Friends and Family About Your Plans</h3>
      <p>Tell a friend or family member of your plans, including when and where you’re going. Have your cell phone charged and with you at all times.</p>
      <h3 style={{ color: '#5E74FE' }}>Be in Control of Your Transportation</h3>
      <p>We want you to be in control of how you get to and from your Photogig so that you can leave whenever you want. If you’re driving yourself, it’s a good idea to have a backup plan such as a ride-share app or a friend to pick you up.</p>
      <h3 style={{ color: '#5E74FE' }}>If You Feel Uncomfortable, Leave</h3>
      <p>It’s okay to end the photogig early if you’re feeling uncomfortable. In fact, it’s encouraged. And if your instincts are telling you something is off or you feel unsafe, ask someone around you for help.</p>
    </Container>
  )
}

export default Terms