import Container from '@mui/material/Container'

import Logo from '../components/Logo';

const Terms = () => {
  return (
    <Container>
      <Logo mode="color" noMargin />
      <p>Photogig (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (the “Application”).   Please read this Privacy Policy carefully.  IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.</p>
      <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason.  We will alert you about any changes by updating the “Last updated” date of this Privacy Policy.  You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.</p>
      <p>This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you.  We are not responsible for any of the data collected by any such third party.</p>
      <h2 style={{ color: '#5E74FE' }}>COLLECTION OF YOUR INFORMATION</h2>
      <p>We may collect information about you in a variety of ways.  The information we may collect via the Application depends on the content and materials you use, and includes:</p>
      <h3 style={{ color: '#5E74FE' }}>Personal Data</h3>
      <p>Please be advised that all data you disclose when you choose to share data about yourself when you signup, visible on your profile, or other interactive areas of the Application such as your location on the app map is considered public and will be accessible to anyone who accesses the Application.</p>
      <h3 style={{ color: '#5E74FE' }}>Derivative Data</h3>
      <p>Information collected by our servers when you use the Application for its designated function, such as collecting pictures and videos captured by you and your connections, are stored in our cloud servers. They are not visible by other users, however Photogig does not take responsibility of unwilling leakage of this data by the company or associated business partners, like for example on the unlikely event that the cloud server is hacked. This information might also be accessible by staff responsible for maintenance and monitoring of the application.</p>
      <h3 style={{ color: '#5E74FE' }}>Data from Social Networks</h3>
      <p>User information from social networking sites, such as [Apple’s Game Center, Facebook, Google+ Instagram, Pinterest, Twitter], including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks. This information may also include the contact information of anyone you invite to use and/or join the Application.</p>
      <p>The Application offers you the possibility to share media captured via social media, and this is considered public information.</p>
      <h3 style={{ color: '#5E74FE' }}>Geo-Location Information</h3>
      <p>We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.</p>
      <h3 style={{ color: '#5E74FE' }}>Mobile Device Access</h3>
      <p>We may request access or permission to certain features from your mobile device, including your mobile device’s [bluetooth, calendar, camera, contacts, microphone, reminders, sensors, SMS messages, social media accounts, storage,] and other features. If you wish to change our access or permissions, you may do so in your device’s settings.</p>
      <h3 style={{ color: '#5E74FE' }}>Mobile Device Data</h3>
      <p>Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide (if requested) are also considered public information.</p>
      <h3 style={{ color: '#5E74FE' }}>Push Notifications</h3>
      <p>We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.</p>
      <h2 style={{ color: '#5E74FE' }}>USE OF YOUR INFORMATION</h2>
      <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience.  Specifically, we may use information collected about you via the Application to:</p>
      <ol>
        <li>Assist law enforcement and respond to subpoena.</li>
        <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
        <li>Create and manage your account.</li>
        <li>Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Application to you.</li>
        <li>Email you regarding your account or order.</li>
        <li>Enable user-to-user communications.</li>
        <li>Generate a personal profile about you to make future visits to the Application more personalized.</li>
        <li>Increase the efficiency and operation of the Application.</li>
        <li>Monitor and analyze usage and trends to improve your experience with the Application.</li>
        <li>Notify you of updates to the Application.</li>
        <li>Offer new products, services, mobile applications, and/or recommendations to you.</li>
        <li>Perform other business activities as needed.</li>
        <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
        <li>Request feedback and contact you about your use of the Application. </li>
        <li>Resolve disputes and troubleshoot problems.</li>
        <li>Respond to product and customer service requests.</li>
      </ol>

      <h2 style={{ color: '#5E74FE' }}>DISCLOSURE OF YOUR INFORMATION</h2>
      <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>

      <h3 style={{ color: '#5E74FE' }}>By Law or to Protect Rights</h3>
      <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.  This includes exchanging information with other entities for fraud protection and credit risk reduction.</p>
      <h3 style={{ color: '#5E74FE' }}>Third-Party Service Providers</h3>
      <p>We may share your anonymized information with third parties that perform services for us or on our behalf, including location, data analysis, email delivery, hosting services, customer service, and marketing assistance.  </p>
      <h3 style={{ color: '#5E74FE' }}>Marketing Communications</h3>
      <p>With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.</p>
      <h3 style={{ color: '#5E74FE' }}>Interactions with Other Users</h3>
      <p>If you interact with other users of the Application, those users may see your name, profile photo, and descriptions of your activity. </p>
      <h3 style={{ color: '#5E74FE' }}>Online Postings</h3>
      <p>When you post comments, contributions or other content to the Applications, your posts may be viewed by all users and may be publicly distributed outside the Application in perpetuity</p>
      <h3 style={{ color: '#5E74FE' }}>Affiliates</h3>
      <p>We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</p>
      <h3 style={{ color: '#5E74FE' }}>Business Partners</h3>
      <p>We may share your information with our business partners to offer you certain products, services or promotions. </p>
      <h3 style={{ color: '#5E74FE' }}>Sale or Bankruptcy</h3>
      <p>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity.  If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party.  You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy. </p>


      <h2 style={{ color: '#5E74FE' }}>SECURITY OF YOUR INFORMATION</h2>
      <p>We use administrative, technical, and physical security measures to help protect your personal information.  While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.  Any information disclosed online is vulnerable to interception and misuse by unauthorized parties.  Therefore, we cannot guarantee complete security if you provide personal information.</p>
      <h2 style={{ color: '#5E74FE' }}>POLICY FOR CHILDREN</h2>
      <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>
      <h2 style={{ color: '#5E74FE' }}>CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
      <p>Most web browsers and some mobile operating systems [and our mobile applications] include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.  No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.  If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</p>
      <h2 style={{ color: '#5E74FE' }}>OPTIONS REGARDING YOUR INFORMATION</h2>
      <p>You may at any time review or change the information in your account by logging into your account settings and changing your password. If you want your account to be deleted, please email us at photogigger@gmail.com</p>

      <h2 style={{ color: '#5E74FE' }}>CONTACT US</h2>
      <p>If you have questions or comments about this Privacy Policy, please contact us at photogigger@gmail.com</p>
    </Container>
  )
}

export default Terms