import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NextButton = styled.button`
  padding: 5px 20px;
  font-size: 22px;
  margin-right: 10px;
  background-color: ${(props) => props.disabled ? "#EBEBE4" : "#4150A4" };
  border: solid 1px white;
  color: white;
  margin-right: 10px;
  border-radius: 5px;
`

const ValueInput = styled.input`
  font-size: 22px;
  text-align: right;
  margin-bottom: 20px;
  margin: 5px;
  padding-right: 30px;
  width: 140px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: 'space-between';
  align-items: center;
`

const PriceButton = styled.button`
  padding: 10px 20px;
  font-size: 22px;
  width: 80px;
  background-color: #4150A4;
  color: white;
  margin: 10px;
  border: 0;
  font-weight: 500;
  border-radius: 5px;
`

const CustomAmountButton = styled.button`
  padding: 10px 20px;
  font-size: 22px;
  width: 100%;
  background-color: transparent;
  color: #4150A4;
  border: 0;
`

const Currency = styled.span`
  font-size: 22px;
  position: absolute;
  right: 20px;
  top: 9px;
`

const PriceInput = ({ onSubmit }) => {
  const [value, setValue] = useState(null)
  const [custom, setCustom] = useState(false)

  const handleTextInputChange = (e) => {
    const newValue = parseInt(e.target.value, 10)

    if (Number.isInteger(newValue) && newValue > 0) {
      if (newValue < 5) {
        window.alert('Price must be at least 5€');
      } else {
        setValue(newValue)
      }
    } else {
      setValue(null)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (value < 5) {
      window.alert('Price must be at least 1€')
      return
    }
    if (value !== null) {
      onSubmit(value)
    }
  }

  return (
    <Container>
      <h2>Pay what you want</h2>
      <ButtonsRow>
        <PriceButton onClick={() => onSubmit(5)}>
          5€
        </PriceButton>
        <PriceButton onClick={() => onSubmit(10)}>
          10€
        </PriceButton>
        <PriceButton onClick={() => onSubmit(15)}>
          15€
        </PriceButton>
      </ButtonsRow>
      <ButtonsRow style={{marginBottom: 20 }}>
        <div style={{position: 'relative', margin: 10 }}>
          <ValueInput
            type="text"
            placeholder="Enter value..."
            value={value}
            onChange={handleTextInputChange}
            />
          <Currency>€</Currency>
        </div>
        <NextButton onClick={handleSubmit} disabled={!value}>
          Pay
        </NextButton>
      </ButtonsRow>
    </Container>
  )
}

export default PriceInput