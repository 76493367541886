import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { isIOS, isAndroid } from 'react-device-detect';

import Button from '../WhiteButton';

const Hero = () => {
  const handleAppDownload = () => {
    if (isIOS) {
      window.open('https://apps.apple.com/us/app/photogig/id1570678555', '_blank');
    } else if (isAndroid) {
      // alert user app is not yet available on android
      window.alert("The app is not yet available on Android. We are working on it and hope to have it ready very soon!")
    } else {
      // TODO: show branch.io link as qrcode
      // alert user to use a mobile device
      window.alert("Please use a mobile device to download the app.")
    }
  }
  return (
    <Box sx={{
      minHeight: "80vh",
      marginTop: '-100px',
      paddingTop: '100px',
      backgroundImage: 'linear-gradient(rgba(94, 116, 254, 0.67), rgba(94, 116, 254, 0.87)), url(../background.jpg)',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: 'flex',
      alignItems: 'center',
    }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: 'center',
            alignItems: 'space-between',
            flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }
          }}
        >
          <Box
            sx={{
              flex: "1",
              display: 'flex',
              flexDirection: 'column',
              marginBottom: { xs: '30px', md: '0' },
              alignItems: 'flex-start',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                marginTop: { xs: '100px', sm: '100px', md: '200px'},
                color: 'white',
                fontSize: { xs: '48px', lg: '56px' },
                fontStyle: 'normal',
                fontWeight: 'bold',
              }}
            >
              Create Life Memories
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "18px", color: "#5A6473", my: 4, color: 'white', marginRight: { sm: '50px'} }}
            >
              Find photographers near you and hire them for any type of photographic services, pay using the photogig checkout and get the pictures instantly.
            </Typography>
            {/* <Button
              onClick={handleAppDownload}
              variant="contained"
            >
              Download the app
            </Button> */}
          </Box>
          <Box sx={{ alignItems: 'center', justifyContent: 'center', display: { xs: 'none', sm: 'flex'} }}>
            <Box
              component="img"
              src="../map.png"
              sx={{
                position: 'relative',
                maxWidth: { xs: '120px', sm: '140px', md: '180px', lg: '200px' },
              }}
              alt="map"
            />
            <Box
              component="img"
              src="../camera.png"
              sx={{
                maxWidth: { xs: '120px', sm: '140px', md: '180px', lg: '200px' },
                marginLeft: { xs: '-20px', sm: '-20px', md: '-50px', lg: '-50px' },
                marginBottom: { xs: '10px', sm: '10px', md: '30px', lg: '30px' },
              }}
              alt="camera"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Hero;