import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Logo = ({ mode = 'light', noMargin = false }) => {
  return (
    <>
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', mr: noMargin ? 0 : 1 }}>
        <Box
          component="img"
          src={mode === 'light' ? "/logo256-white.png" : "/logo256.png"}
          sx={{
            height: { xs: '40px', sm: '60px' },
          }}
          alt="logo"
        />
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: noMargin ? 0 : 2,
            display: 'flex',
            fontWeight: 700,
            fontSize: 26,
            color: mode === 'light' ? 'white' : '#5E74FE',
            textDecoration: 'none',
            fontStyle: 'normal',
          }}
        >
          Photogig
        </Typography>
      </Box>
    </>
  )
}

export default Logo;