import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useNavigate } from 'react-router-dom';

const Featured = () => {
  const mapWidth = window.innerWidth - 20;
  // const [isLoading, setIsLoading] = useState(true)
  // const [isError, setIsError] = useState(false)
  // const [featuredPhotographers, setFeaturedPhotographers] = useState([])
  const navigate = useNavigate();
  const featuredPhotographers = [
    {
      username: 'franciscolemos',
      name: 'Francisco Lemos',
      email: 'franciscostreet01@gmail.com',
      bio: 'Capturing moments with creativity and precision, transforms ordinary scenes into extraordinary visual stories.',
      profile_photo: '/franciscolemos.jpeg',
    },
  ];


  // useEffect(() => {
  //   async function fetchFeatured() {
  //     try {
  //       console.log('FETCHING FEATURED PHOTOGRAPHERS')
  //       console.log(`${process.env.REACT_APP_PHOTOGRAPHERS_API_URL}/featured`)
  //       const response = await fetch(`${process.env.REACT_APP_PHOTOGRAPHERS_API_URL}/featured`);
  //       const data = await response.json()
  //       console.log({data})
  //       setFeaturedPhotographers(data)
  //       setIsLoading(false)
  //     } catch (error) {
  //       console.log({error})
  //       setIsError(true)
  //     }
  //   }

  //   setIsLoading(true)
  //   fetchFeatured()
  // }, [])

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      paddingTop: { xs: '20px', sm: '40px', md: '100px'},
      paddingBottom: { xs: '20px', sm: '40px', md: '100px'},
      backgroundColor: '#5E74FE',
    }}>
      <Container>
      <Typography
          variant="h3"
          sx={{
            marginBottom: { xs: '20px', md: '40px' },
            fontSize: { xs: '28px', lg: '36px' },
            fontStyle: 'normal',
            fontWeight: 'bold',
            textAlign: 'center',
            color: 'white',
          }}
        >
          Hire one of our featured photogiggers
        </Typography>

        <Grid container spacing={2}>
          {featuredPhotographers.map((photographer, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                backgroundColor: 'white',
                borderRadius: '10px',
              }}>
                <img src={photographer.profile_photo || '/logo256.png'} alt={photographer.name} style={{ width: 100, height: 100, borderRadius: '50px' }} />
                <Typography
                  variant="h5"
                  sx={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: 'black',
                  }}
                >
                  {photographer.username}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: '10px',
                    fontSize: '16px',
                    fontWeight: 'normal',
                    textAlign: 'center',
                    color: 'black',
                    // make italic
                    fontStyle: 'italic',
                  }}
                >
                  {photographer.bio}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#5E74FE',
                    color: 'white',
                    marginTop: '10px',
                  }}
                  onClick={() => navigate(`/photographer/${photographer.username}`, { state: { photographer }})}
                >
                  Details
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '40px',
        }}>
          <Button
            variant="text"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            onClick={() => navigate('/photographers')}
          >
            Want to become a featured photographer? Tap here!
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Featured;