import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const Map = () => {
  const mapWidth = window.innerWidth - 20;

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      paddingTop: { xs: '20px', sm: '40px', md: '100px'},
      paddingBottom: { xs: '20px', sm: '40px', md: '100px'},
    }}>
      <Container>
      <Typography
          variant="h3"
          sx={{
            marginBottom: { xs: '20px', md: '40px' },
            fontSize: { xs: '28px', lg: '36px' },
            fontStyle: 'normal',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Find the top "Instagrammable" spots in Lisbon
        </Typography>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <iframe src="https://www.google.com/maps/d/embed?mid=1dLtxi89tg7jzSUu8pySVvlhFVtxX0ec&ehbc=2E312F" width="640" height="480"></iframe>
        </Box>
      </Container>
    </Box>
  );
}

export default Map;