import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Logo from '../components/Logo';
import Spinner from '../components/Spinner'
import Checkout from '../components/Checkout'
import EmailInput from '../components/EmailInput'
import PriceInput from '../components/PriceInput'
import Photos from '../components/Photos';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const Preview = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const sessionId = searchParams.get('sessionId')
  const authCode = searchParams.get('authCode')
  const [isPaid, setIsPaid] = useState(false)
  const [price, setPrice] = useState(undefined)
  const [email, setEmail] = useState(undefined)
  const [skippedEmail, setSkippedEmail] = useState(false)
  const [priceRequired, setPriceRequired] = useState(false)
  const [thumbnailUrls, setThumbnailUrls] = useState('')
  const [photoUrls, setPhotoUrls] = useState('')
  const [stripeSecret, setStripeSecret] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    async function fetchSession() {
      try {
        const body = { auth_code: authCode, price }
        if (email) body.email = email
        const response = await fetch(`${process.env.REACT_APP_API_URL}/session/${sessionId}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authCode}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        })
        const data = await response.json()
        console.log({data})
        setPriceRequired(data.price_required)
        if (!price) setPrice(data.price)
        setThumbnailUrls(data.thumbnail_urls)
        setPhotoUrls(data.photo_urls)
        setStripeSecret(data.stripe_secret || data.stripeSecret)
        setIsPaid(data.is_paid || data.is_free)
        setIsLoading(false)
      } catch (error) {
        setIsError(true)
      }
    }

    setIsLoading(true)
    fetchSession()
  }, [authCode, price])

  if (isError) return <div>Something went wrong, try refreshing the page. If the error persists email us at <a href="mailto:hello@photogig.app">hello@photogig.app</a></div>
  if (isLoading) return <LoadingContainer><Spinner /><p>Loading, please wait</p></LoadingContainer>
  const options = {
    // passing the client secret obtained from the server
    clientSecret: stripeSecret,
  };
  console.log({ options })
  console.log({ photoUrls, thumbnailUrls, isPaid})
  return (
    <Container>
      <Logo mode="color" noMargin />
      <Photos isPaid={isPaid} photoUrls={photoUrls} thumbnailUrls={thumbnailUrls} />
      {!isPaid && priceRequired ? (
        <PriceInput onSubmit={setPrice} />
      ) : null }
      {!email && !skippedEmail && !isPaid && !priceRequired ? (
        <EmailInput onSubmit={setEmail} onSkip={() => setSkippedEmail(true)}/>
      ) : null }
      {(email || skippedEmail) && !isPaid && !priceRequired ? (
        <Elements stripe={stripePromise} options={options}>
          <Checkout price={price}/>
        </Elements>
      ) : null }
    </Container>
  );
}

export default Preview
