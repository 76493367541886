import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const Footer = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#5E74FE',
        paddingTop: { xs: '20px', sm: '20px', md: '20px'},
        paddingBottom: { xs: '20px', sm: '20px', md: '20px'},
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <a className="App-footer__social" href="https://www.facebook.com/photogigapp" target="_blank"><FacebookIcon sx={{ fontSize: 32, color: 'white', marginRight: '5px' }} /></a>
          <a className="App-footer__social" href="https://www.instagram.com/photogig.app/" target="_blank"><InstagramIcon sx={{ fontSize: 32, color: 'white' }} /></a>
          <a className="App-footer__social" href="https://www.youtube.com/channel/UCmHcGF5Vf3hRcXHdulOxRKw" target="_blank"><YouTubeIcon  sx={{ fontSize: 32, color: 'white', marginLeft: '5px' }}/></a>
        </Box>
        <Typography
          sx={{
            // fontSize: { xs: '32px', lg: '48px' },
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Photogig, Lda
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;