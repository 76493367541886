import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';

const BootstrapButton = styled(Button)({
  fontSize: 18,
  backgroundColor: 'white',
  color: '#5E74FE',
  '&:hover': {
    backgroundColor: '#5E74FE',
    borderColor: '#5E74FE',
    color: 'white',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

export default BootstrapButton;