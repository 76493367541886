import Box from '@mui/material/Box'

import Navbar from '../components/Navbar'
import Hero from '../components/Home/Hero'
import Summary from '../components/Home/Summary'
import Footer from '../components/Footer';
import Featured from '../components/Home/Featured';
import Map from '../components/Home/Map';

const Home = () => {
  return (
    <Box>
      <Navbar />
      <Hero />
      <Map />
      <Featured />
      <Summary />
      <Footer />
    </Box>
  );
}

export default Home