import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import EmailIcon from '@mui/icons-material/Email';

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Photographer = () => {
  const { state } = useLocation()
  const { photographer } = state

  const photographerContainer = (
    <Container>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
      }}>
        <img src={photographer.profile_photo || '/logo256.png'} alt={photographer.name} style={{ width: 100, height: 100, borderRadius: '50px' }} />
        <Typography
          variant="h5"
          sx={{
            marginTop: '20px',
            marginBottom: '10px',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 'bold',
            textAlign: 'center',
            color: 'black',
          }}
        >
          {photographer.username}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '10px',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            textAlign: 'center',
            color: 'black',
          }}
        >
          {photographer.bio}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginTop: 25 }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: '800',
              marginBottom: 0,
              paddingBottom: 0,
              lineHeight: '1em',
            }}
            >
            25€
          </Typography>
          <Typography
            variant="h4"
          >
            /hour
          </Typography>
        </div>
        <Typography
          variant="body1"
          sx={{
            fontStyle: 'italic',
            color: 'black',
            marginTop: '5px',
            marginBottom: '25px',
          }}
        >
          +10€ for groups of 3 or more
        </Typography>

        <Button
          variant="contained"
          sx={{
            backgroundColor: '#5E74FE',
            color: 'white',
            marginTop: '10px',
            marginBottom: '10px',
          }}
          rel="noopener noreferrer"
          href={`mailto:${photographer.email}`}
        >
          <EmailIcon sx={{ marginRight: '5px' }} /> Email me
        </Button>

        <Typography
          variant="body1"
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            textAlign: 'center',
            color: 'black',
            marginTop: '10px',
          }}
        >
          Or contact me through my instagram:
        </Typography>
      </Box>
      <iframe src='https://d6ad947e86684d5c81fb12e213f6e34a.elf.site' width='100%' height='1000' frameborder='0'></iframe>
    </Container>
  );

  return (
    <Box>
      <Navbar withBackground />
      {photographerContainer}
      <Footer />
    </Box>
  )
}

export default Photographer
