import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { isIOS, isAndroid } from 'react-device-detect';

const Summary = () => {
  const handleAppDownload = () => {
    if (isIOS) {
      window.open('https://apps.apple.com/us/app/photogig/id1570678555', '_blank');
    } else if (isAndroid) {
      // alert user app is not yet available on android
      window.alert("The app is not yet available on Android. We are working on it and hope to have it ready very soon!")
    } else {
      // TODO: show branch.io link as qrcode
      // alert user to use a mobile device
      window.alert("Please use a mobile device to download the app.")
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      paddingTop: { xs: '20px', sm: '40px', md: '100px'},
      paddingBottom: { xs: '20px', sm: '40px', md: '100px'},
    }}>
      <Container>
        <Typography
          variant="h3"
          sx={{
            marginBottom: { xs: '20px', md: '40px' },
            fontSize: { xs: '28px', lg: '36px' },
            fontStyle: 'normal',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Professional pictures in 3 easy steps
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#E8E9F3',
                  height: '50px',
                  width: '50px',
                  borderRadius: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* <LocationOnIcon sx={{ fontSize: 32 }} /> */}
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "32px",
                    fontWeight: 'bold',
                    my: 1
                  }}
                >
                  1
                </Typography>
              </Box>
              <Typography
                variant="h4"
                sx={{
                  fontSize: "18px",
                  fontWeight: 'bold',
                  color: '#5E74FE',
                  my: 1
                }}
              >
                Find photographers
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "14px", my: 1 }}
              >
                Find active photographers using the map or search for specific photographers by typing their handle on the "Find users" search.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#E8E9F3',
                  height: '50px',
                  width: '50px',
                  borderRadius: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* <ContactSupportIcon sx={{ fontSize: 32 }} /> */}
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "32px",
                    fontWeight: 'bold',
                    my: 1
                  }}
                >
                  2
                </Typography>
              </Box>
              <Typography
                variant="h4"
                sx={{
                  fontSize: "18px",
                  fontWeight: 'bold',
                  color: '#5E74FE',
                  my: 1
                }}
              >
                Request a Photogig
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "14px", my: 1 }}
              >
                Request a photo session from the photographer by tapping on the "Request Photogig" button on their profile.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#E8E9F3',
                  height: '50px',
                  width: '50px',
                  borderRadius: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* <PhotoLibraryIcon sx={{ fontSize: 32 }} /> */}
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "32px",
                    fontWeight: 'bold',
                    my: 1
                  }}
                >
                  3
                </Typography>
              </Box>
              <Typography
                variant="h4"
                sx={{
                  fontSize: "18px",
                  fontWeight: 'bold',
                  color: '#5E74FE',
                  my: 1
                }}
              >
                Pay and get the pictures
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "14px", my: 1 }}
              >
                Pay the photographer using the photogig checkout. You will receive all the pictures in the app and you can download or share them to your favorite social media.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '40px',
        }}>
          <Button
            onClick={handleAppDownload}
            variant="contained"
          >
            Download the app
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Summary;