

// const SpinningLogo = () => (
//   <SpinningLogoContainer>
//     <Circle viewBox="0 0 80 80">
//       <CirclePath cx="40" cy="40" r="35" />
//     </Circle>
//     <LogoImage src="/logo.png" alt="logo" />

//   </SpinningLogoContainer>
// );

// export default SpinningLogo;

import React from 'react';
import styled from 'styled-components';

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: 0;
  width: 50px;
  height: 50px;

  & .path {
    stroke: #4150A4;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const Spinner = () => (
  <StyledSpinner viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
);

export default Spinner;