import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NextButton = styled.button`
  padding: 5px 20px;
  font-size: 22px;
  background-color: ${(props) => props.disabled ? "#EBEBE4" : "#4150A4" };
  border: solid 1px white;
  color: white;
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 10px;
`

const SkipButton = styled.button`
  padding: 5px 20px;
  font-size: 22px;
  background-color: ${(props) => props.disabled ? "#EBEBE4" : "#4150A4" };
  border: solid 1px white;
  color: white;
  width: 100%;
  border-radius: 5px;
`

const Input = styled.input`
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px 0;
  width: 100%;
`

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const EmailInput = ({ onSubmit, onSkip }) => {
  const [email, setEmail] = useState(null)

  const handleTextInputChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const isValidEmail = emailRegex.test(email);
    if (!isValidEmail) {
      window.alert('Invalid email')
      return
    }
    if (email !== null) {
      onSubmit(email)
    }
  }

  return (
    <Container>
      <h2>Do you want a receipt?</h2>

      <Input
        type="text"
        placeholder="email@email.com"
        value={email}
        onChange={handleTextInputChange}
      />

      <NextButton onClick={handleSubmit} disabled={!email}>
        Next
      </NextButton>
      <SkipButton onClick={onSkip}>
        No, thanks
      </SkipButton>
    </Container>
  )
}

export default EmailInput