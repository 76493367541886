import React, { useEffect, useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components'

const stripePromise = loadStripe('pk_test_lLS9sEmL8to3FXiLDVSCW4rZ');

const CheckoutContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PaymentButton = styled.button`
  padding: 10px 20px;
  font-size: 22px;
  background-color: #4150A4;
  border: solid 1px white;
  color: white;
  margin-top: 10px;
  width: 100%;
  border-radius: 5px;
`

const Checkout = ({ clientSecret, price }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href,
      },
    });
    if (error) {
      console.log(error);
    } else {
      // axios.post('/buy', {
      //   sessionId: props.sessionId,
      //   authCode: props.authCode,
      //   paymentMethodId: paymentMethod.id,
      // });
      // setIsPaid(true);
    }
  };

  const paymentOptions = {
    country: ['PT']
  }

  return (
    <CheckoutContainer onSubmit={handleSubmit}>
      <PaymentElement clientSecret={clientSecret} options={paymentOptions}/>
      <PaymentButton type="submit" disabled={!stripe}>
        Pay {price}€
      </PaymentButton>
    </CheckoutContainer>
  )
}

export default Checkout